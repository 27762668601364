import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  team: null,
  error: null,
  loading: true,
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    teamPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    teamSuccess: (state, { payload }) => {
      state.loading = false;
      state.team = payload;
    },
    teamRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
