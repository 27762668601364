import styled from "styled-components";
import heroBg from "../assets/images/about-us.png";

export const SingleTeamHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const InfoContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
  gap: 120px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 100px;
    gap: 70px;
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const LeftSide = styled.div`
  max-width: 620px;
  margin-top: 100px;
`;

export const RightSide = styled.div`
  margin-top: 100px;
  max-width: 620px;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const CoverImage = styled.div`
  img {
    width: 645px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 340px;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    img {
      width: 500px;
    }
  }
`;

export const Info = styled.div`
  width: 504px;
  margin-bottom: 100px;

  h3 {
    color: var(--FFFFFC, #fffffc);
    font-size: 20px;
    font-style: normal;
    line-height: 30px; /* 150% */
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }

  h4 {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-weight: 700;
    line-height: 30px; /* 187.5% */
    margin-top: 20px;

    span {
      color: var(--FFFFFC, #fffffc);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }

  @media screen and (max-width: 500px) {
    width: 340px;
    margin-bottom: 0px;

    h3 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }

    h4 {
      font-size: 14px;
      span {
        font-size: 14px;
      }
    }
  }

  @media (min-width: 501px) and (max-width: 768px) {
    width: 95%;
    margin: 0 auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
  }
`;
