import styled from "styled-components";
import heroBg from "../assets/images/architectural-hero.png";

export const ArchitecturalHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const ArchitecturalContainer = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
`;

export const GridContainer = styled.div`
  margin-top: 150px;
  margin-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;

  @media screen and (max-width: 768px) {
    margin-top: 100px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    grid-gap: 20px;
  }
`;

export const GridItem = styled.div`
  transition: all 300ms linear;

  h2 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 14px;
    font-style: normal;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    h2 {
      font-size: 22px;
      max-width: 370px;
      margin-top: 20px;
    }

    p {
      font-size: 14px;
      max-width: 370px;
    }
  }
`;

export const ImageContainer = styled.div`
  width: 450px;
  height: 360px;
  transition: all 300ms linear;
  overflow: hidden;

  img {
    width: 450px;
    height: 360px;
    object-fit: cover;
    margin-bottom: 30px;
    transition: all 300ms linear;
  }

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    width: 370px;
    height: 300px;
    img {
      width: 370px;
      height: 300px;
    }
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 415px;
    height: 325px;
    img {
      width: 415px;
      height: 325px;
    }
  }
`;

export const ButtonContainer = styled.div`
  margin-bottom: 200px;

  @media screen and (max-width: 768px) {
    margin-bottom: 160px;
  }
`;

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(39, 39, 39, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

export const ModalContent = styled.div`
  max-width: 1000px;
  max-height: 800px;
  width: 100%;
  height: 100%;
  background-color: rgba(39, 39, 39, 1);
  position: relative;

  @media screen and (max-width: 768px) {
    max-width: 370px;
    height: 100vh;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;

  svg {
    font-size: 40px;
  }

  @media screen and (max-width: 768px) {
    svg {
      font-size: 30px;
    }
  }
`;

export const SliderItem = styled.div`
  img {
    width: 520px;
    height: 420px;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 240px;
      height: 195px;
    }
  }
`;

export const SliderContainer = styled.div`
  max-width: 520px;
  max-height: 420px;
  & > div {
    top: 60px;
    left: calc(50% - 20px);
  }

  @media screen and (max-width: 768px) {
    max-width: 240px;
    max-height: 195px;
    & > div {
      top: 50px;
      left: calc(50% - 55px);
    }
  }
`;

export const NextArrowStyles = styled.div`
  width: 30px;
  height: 30px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: calc(50% - 15px);
  right: calc(100% + 15px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: calc(50% - 15px);
    width: 30px;
    height: 30px;

    svg {
      font-size: 16px;
    }
  }
`;

export const PrevArrowStyles = styled.div`
  width: 30px;
  height: 30px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: calc(50% - 15px);
  left: calc(100% + 15px);
  transition: all 300ms linear;
  z-index: 999;
  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: calc(50% - 15px);
    width: 30px;
    height: 30px;

    svg {
      font-size: 16px;
    }
  }
`;

export const ModalText = styled.div`
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;

  h2 {
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 20px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    max-width: 365px;
    margin-top: 100px;
    h2 {
      font-size: 22px;
    }

    p {
      font-size: 14px;
    }
  }
`;
