import axe from "../../config/axiosClient/axiosConfig";
import caller from "./teamCaller";

export const getTeamMember = async (slug) => {
  try {
    caller.teamPending();
    const res = await axe.get(`/team/${slug}`);
    caller.teamSuccess(res.data.data);
  } catch (error) {
    caller.teamRejected("Something went wrong!");
  }
};
