import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout'
import { HeroContainer, HeroText, AboutHomeSection, AboutHomeLeft, AboutHomeRight, ContentAboutHome, HomeProjects, HomeProjectsTitleContent, HomeProjectsList, ProjectLongImg, ProjectWideImg, ProjectTitle, VideoContainer, HomeCategories, HomeCategoriesTitleContent, ImageContainerWide, ImageContainerLong } from './Home.styles'
import aboutImg from '../assets/images/about-home.svg'
import ButtonLink from '../components/reusable/ButtonLink'
import videoSection from '../assets/images/videosection.png'
import { Link } from 'react-router-dom'
import GetInTouch from '../components/common/GetInTouch'
import ArchitectureSilder from '../components/common/ArchitectureSilder'
import { BACKEND_API_FILE_URL } from '../config/urls'
import PageLoader from '../components/reusable/PageLoader'
import { getProjects } from '../features/home/homeActions'
import { useSelector } from 'react-redux'
import ModalArchitecture from '../components/common/ModalArchitecture'
import { Helmet } from 'react-helmet'


function Home() {
  const { loading, projects, architecture } = useSelector(store => store.home)
  const [showModal, setshowModal] = useState({
    visibility: false,
    id: ''
  })

  const fetchProjects = () => {
    getProjects();
  }

  useEffect(() => {
    fetchProjects()
  }, [])


  const handleModal = (id) => {
    setshowModal({
      visibility: !showModal.visibility,
      id: id
    })
  }


  if (loading) {
    return <PageLoader />;
  }


  return (
    <>
      <Helmet>
        <title data-n-head="true">ARKITEKTI | Studio Arkitekturore | Tirane, Shqiperi  </title>
        <meta name="description" content="ARKITEKT ofron paketa të plota shërbimesh që nga krijimi deri tek realizimi i projektit. Studio realizon studime dhe kërkime në fushën e arkitekturës, urbanisikës, dizajnit, interiorit dhe eksteriorit…" />
        <meta name="apple-mobile-web-app-title" content="ARKITEKTI | Studio Arkitekturore | Tirane, Shqiperi " />
        <meta name="keywords" content="arkitekti, arkitekt, projektim, planifikim, dizejnim, supervizim, realizim, interior, exterior, studio, studjo, arkitekture, zyre, tirane, Shqiperi, dizajn, ndertesa, arredim, zyra, ndertesa, shtepi " />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="twitter:card" content="https://arkitekti.al/images/icon.png" />
        <meta name="twitter:description" content=" ARKITEKT ofron paketa të plota shërbimesh që nga krijimi deri tek realizimi i projektit. Studio realizon studime dhe kërkime në fushën e arkitekturës, urbanisikës, dizajnit, interiorit dhe eksteriorit…" />
        <meta name="twitter:title" content="ARKITEKTI | Studio Arkitekturore | Tirane, Shqiperi" />
        <meta name="twitter:image" content="https://arkitekti.al/images/icon.png" />
        <meta property="ia:markup_url" content="https://arkitekti.al/?ia_markup=1" />
        <meta property="og:locale" content="sq_AL" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="ARKITEKTI | Studio Arkitekturore | Tirane, Shqiperi" />
        <meta property="og:description" content=" ARKITEKT ofron paketa të plota shërbimesh që nga krijimi deri tek realizimi i projektit. Studio realizon studime dhe kërkime në fushën e arkitekturës, urbanisikës, dizajnit, interiorit dhe eksteriorit…" />
        <meta property="og:image" content="https://arkitekti.al/images/icon.png" />
        <meta property="og:url" content="https://arkitekti.al/" />
        <meta property="og:image:secure_url" content="https://arkitekti.al/images/icon.png? " />
        <meta property="og:image:type" content="image/png images.jpg" />
        <meta property="og:image:alt" content="ARKITEKTI | Studio Arkitekturore | Tirane, Shqiperi" />


      </Helmet>
      <Layout>
        {showModal.visibility && <ModalArchitecture handleModal={handleModal} showModal={showModal} />}
        <HeroContainer>
          <HeroText>
            <div className='w-[100%] md:w-[55%] home-hero-text'>
              <h1 className='font-roboto text-white'>Turning Your Visions into Reality</h1>
              <p className='text-white font-verdana mt-2'>
                We specialize in transforming your dreams into tangible realities, seamlessly integrating them onto objects or surfaces that enhance your daily experiences.
              </p>
              <p className='text-white font-verdana'>
                Immerse yourself in a world where your dreams become a source of continual joy and satisfaction.
              </p>
            </div>
          </HeroText>
        </HeroContainer>
        <AboutHomeSection>
          <AboutHomeLeft>
            <img src={aboutImg} alt="" />
          </AboutHomeLeft>
          <AboutHomeRight>
            <ContentAboutHome>
              <h4 className='font-verdanaBold'>About Us</h4>
              <h2 className='font-roboto'>
                We are creative architecture Studio
              </h2>
              <p className='font-verdana'>
                Introducing "ARCHITECTURE" Studio, an Albanian design firm established in April 2022. Our mission is to offer comprehensive design services for buildings with diverse functions, along with parks and works of art. Explore our commitment to innovative design as we shape spaces that cater to various needs and artistic expressions.
              </p>
              <ButtonLink label={'Read More About Us'} url={'/about-us'} />
            </ContentAboutHome>
          </AboutHomeRight>
        </AboutHomeSection>
        <HomeProjects>
          <HomeProjectsTitleContent>
            <h4 className='font-verdanaBold'>
              Selected Project
            </h4>
            <h2 className='font-roboto'>
              We identify the essential around new visual perspectives
            </h2>
          </HomeProjectsTitleContent>
          <HomeProjectsList>
            {projects.map((project, index) => (
              index === 0 || index === 3 || index === 4 ? <ProjectLongImg key={index}>
                <Link to={`/project/${project.slug}`}>
                  <ProjectTitle>
                    <h4 className='font-verdanaBold'>
                      {project.category.name}
                    </h4>
                    <h3 className='font-roboto font-bold'>
                      {project.title}
                    </h3>
                  </ProjectTitle>
                  <ImageContainerLong>
                    <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt={project.title} />
                  </ImageContainerLong>
                </Link>
              </ProjectLongImg> : <ProjectWideImg key={index}>
                <Link to={`/project/${project.slug}`}>
                  <ProjectTitle>
                    <h4 className='font-verdanaBold'>
                      {project.category.name}
                    </h4>
                    <h3 className='font-roboto font-bold'>
                      {project.title}
                    </h3>
                  </ProjectTitle>
                  <ImageContainerWide>
                    <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt={project.title} />
                  </ImageContainerWide>
                </Link>
              </ProjectWideImg>
            ))}
          </HomeProjectsList>
        </HomeProjects>
        <VideoContainer>
          <img src={videoSection} alt="" />
        </VideoContainer >
        <HomeCategories>
          <HomeCategoriesTitleContent>
            <h4 className='font-verdanaBold mt-5'>
              Architecture
            </h4>
            <h2 className='font-roboto'>
              The World of architectural form and style
            </h2>
            <p className='font-verdana'>
              Step into a realm where architectural form and style converge to redefine the essence of spatial design and aesthetic expression.
            </p>
            <ButtonLink url={'/architectural'} label={'View more About Architecture'} className={'mx-auto'} />
          </HomeCategoriesTitleContent>
        </HomeCategories>
        <ArchitectureSilder architecture={architecture} handleModal={handleModal} showModal={showModal} setshowModal={setshowModal} />
        <GetInTouch />
      </Layout >
    </>
  )
}

export default Home