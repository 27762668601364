import axe from "../../config/axiosClient/axiosConfig";
import caller from "./aboutCaller";

export const getTeamMembers = async () => {
  try {
    caller.aboutPending();
    const res = await axe.get("/team");
    caller.aboutSuccess(res.data);
  } catch (error) {
    caller.aboutRejected("Something went wrong!");
  }
};
