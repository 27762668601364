import styled from "styled-components";
import heroBg from "../assets/images/single-project.png";

export const SingleProjectHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const ProjectInfoSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    margin-top: 65px;
  }
`;

export const ProjectDescription = styled.div`
  width: 1266px;

  h2 {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    line-height: 30px; /* 187.5% */
    margin-bottom: 30px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
  }

  @media screen and (max-width: 600px) {
    width: 370px;

    h2 {
      font-size: 15px;
      line-height: 26px;
    }

    p {
      font-size: 15px;
      line-height: 26px;
    }
  }
  @media (min-width: 601px) and (max-width: 768px) {
    width: 85%;

    h2 {
      font-size: 15px;
      line-height: 26px;
    }

    p {
      font-size: 15px;
      line-height: 26px;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0 50px;
  }
`;

export const BeforeSection = styled.section``;

export const BeforeContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 150px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin: 100px auto;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin: 100px auto;
  }
`;
export const LeftSide = styled.div`
  width: 60%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SectionImage = styled.div`
  width: 100%;
  img {
    width: 800px;
  }
  @media screen and (max-width: 600px) {
    img {
      width: 370px;
      margin: 0 auto;
    }
  }
  @media (min-width: 601px) and (max-width: 768px) {
    img {
      width: 650px;
      margin: 0 auto;
    }
  }
`;

export const RightSide = styled.div`
  width: 40%;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0 auto;
    width: 90%;
  }
`;

export const SectionText = styled.div`
  h5 {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
  }
`;

export const BeforeTitle = styled.div`
  margin-top: 20px;

  h2 {
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    margin-bottom: 7px;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #ecba82;
    }
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 26px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 0;
    h2 {
      font-size: 17px;
      gap: 10px;

      &::before {
        width: 30px;
      }
    }

    p {
      font-size: 22px;
    }
  }
`;

export const AfterSection = styled.section``;

export const AfterContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 150px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin: 100px auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 50px;
    width: 100%;
    margin: 100px auto;
  }
`;

export const AfterLeftSide = styled.div`
  width: 40%;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 90%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0 auto;
    width: 90%;
  }
`;
export const AfterRightSide = styled.div`
  width: 60%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const OtherProjectsSection = styled.section``;

export const PanoramaSection = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 200px auto;
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const PanoGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  min-height: 150px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const PanoViewer = styled.div`
  margin-top: 70px;
`;

export const PanoRenderer = styled.section`
  canvas {
    width: 100% !important;
  }

  @media screen and (max-width: 768px) {
    height: 300px !important;
    canvas {
      height: 300px !important;
    }
  }
`;

export const PanoGridItem = styled.div`
  cursor: pointer;
  img {
    height: 150px;
    object-fit: cover;
  }
`;

export const ProjectsList = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 200px auto;
  margin-top: 100px;

  & .slick-track {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  & .slick-slide {
    height: inherit !important;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
    padding-top: 30px;
  }
`;
export const ProjectLongImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  transition: all 300ms linear;
  display: table;
  margin: auto 0;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const ProjectTitle = styled.div`
  margin-bottom: -3%;
  z-index: 9999;
  position: relative;
  margin-left: 21px;
  h4 {
    position: relative;
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    h4 {
      font-size: 12px;
      gap: 11px;

      &::before {
        width: 28px;
      }
    }

    h3 {
      font-size: 22px;
    }
  }
`;

export const ImageContainerWide = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 400px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 230px;
  }
`;

export const ImageContainerLong = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 700px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

export const ProjectWideImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  transition: all 300ms linear;
  margin: auto 0;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const NextArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -15%;
  right: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -3%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;

export const PrevArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -15%;
  left: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -3%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;

export const PanoLoader = styled.div`
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
