import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  team: [],
  error: null,
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {
    aboutPending: (state) => {
      state.loading = false;
      state.error = null;
    },
    aboutSuccess: (state, { payload }) => {
      state.loading = false;
      state.team = payload.data;
    },
    aboutRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
