import styled from "styled-components";
import heroBg from "../assets/images/contact-us.png";

export const ContactHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const GetInTouch = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 150px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 100px;
    gap: 70px;
    width: 100%;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  max-width: 620px;
  margin-top: 80px;
`;

export const RightSide = styled.div`
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const GroupList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    gap: 30px;
  }
`;

export const ListItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 45px;

  img {
    width: 50px;
  }
  @media screen and (max-width: 768px) {
    gap: 35px;

    &:last-child {
      img {
        align-self: flex-start;
        margin-top: 10px;
      }
    }

    img {
      width: 40px;
    }
  }
`;

export const ListText = styled.div`
  h2 {
    color: #ecba82;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 200% */

    &::before {
      content: "";
      position: relative;
      width: 18px;
      height: 1px;
      background-color: #ecba82;
    }
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-style: normal;
    line-height: normal;
    font-size: 27px;
    font-style: normal;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 12px;

      &::before {
        width: 16px;
      }
    }

    p {
      font-size: 23px;
    }
  }
`;

export const ContactMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;

  @media screen and (max-width: 450px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
    iframe {
      width: 370px;
      height: 250px;
    }
  }

  @media (min-width: 451px) and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  @media (min-width: 769px) and (max-width: 1440px) {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 100px;
  }
`;
