import React from 'react'
import { MenuModalContainer, ContentWrapper, ModalLogoWrapper, MenuLeft, MenuLeftList, MenuLeftListItem, MenuRight, MenuRightList, MenuIconContainer } from './MenuModal.styles'
import modalLogoWrapper from '../../assets/images/menu-modal-logo-bg.svg'
import logo from '../../assets/images/logo.svg'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import facebookIcon from '../../assets/images/icons/facebook.svg'
import instagramIcon from '../../assets/images/icons/instagram.svg'
import linkedinIcon from '../../assets/images/icons/linkedin.svg'

const variants = {
  enter: {
    opacity: 0, transition: {
      ease: "linear",
      duration: 1,
    }
  },
  visible: {
    opacity: 1, transition: {
      ease: "linear",
      duration: 1,
    }
  },
  exit: {
    opacity: 0, transition: {
      ease: "linear",
      duration: 1,
    }
  }
}

function MenuModal({ toggleMenu }) {
  return (
    <MenuModalContainer className='bg-bgColor' as={motion.div} variants={variants} initial='enter' animate='visible' exit='exit'>
      <ContentWrapper>
        <MenuLeft>
          <MenuLeftList>
            <MenuLeftListItem>
              <Link to={'/'} onClick={toggleMenu} className='font-roboto font-bold'>
                Home
              </Link>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <Link to={'/about-us'} onClick={toggleMenu} className='font-roboto font-bold'>
                About Us
              </Link>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <Link to={'/projects'} onClick={toggleMenu} className='font-roboto font-bold'>
                Projects
              </Link>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <Link to={'/architectural'} onClick={toggleMenu} className='font-roboto font-bold'>
                Architectural
              </Link>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <Link to={'/contact-us'} onClick={toggleMenu} className='font-roboto font-bold'>
                Contact
              </Link>
            </MenuLeftListItem>
          </MenuLeftList>
        </MenuLeft>
        <ModalLogoWrapper
          style={{
            background: `url(${modalLogoWrapper})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <img src={logo} alt="" />
          <h2 className='font-roboto'>Design Your Dream</h2>
        </ModalLogoWrapper>
        <MenuRight>
          <MenuRightList>
            <MenuLeftListItem>
              <MenuIconContainer>
                <a href="https://www.facebook.com/profile.php?id=100086408392316&mibextid=LQQJ4d" target='_blank'>
                  <img src={facebookIcon} alt="Facebook" />
                </a>
              </MenuIconContainer>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <MenuIconContainer>
                <a href="https://www.instagram.com/arkitektiofficial" target='_blank'>
                  <img src={instagramIcon} alt="Instagram" />
                </a>
              </MenuIconContainer>
            </MenuLeftListItem>
            <MenuLeftListItem>
              <MenuIconContainer>
                <a href="https://www.linkedin.com/company/arkitekti" target='_blank'>
                  <img src={linkedinIcon} alt="Linkedin" />
                </a>
              </MenuIconContainer>
            </MenuLeftListItem>
          </MenuRightList>
        </MenuRight>
      </ContentWrapper>
    </MenuModalContainer>
  )
}

export default MenuModal