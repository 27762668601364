import styled from "styled-components";
import heroBg from "../assets/images/projects-hero.png";

export const ProjectsHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const HeaderTextContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;

  h2 {
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #ecba82;
    }
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 50px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 16px;

      &::before {
        width: 30px;
      }
    }

    p {
      font-size: 30px;
    }
  }
`;

export const ProjectsListContainer = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
`;

export const ProjectsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding-bottom: 100px;
  gap: 50px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 60px;
    padding-top: 30px;
    row-gap: 50px;
  }
`;

export const ProjectLongImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  transition: all 300ms linear;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const ProjectWideImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  transition: all 300ms linear;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const ProjectTitle = styled.div`
  margin-bottom: -3%;
  z-index: 9999;
  position: relative;
  margin-left: 21px;
  h4 {
    position: relative;
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    text-shadow: 1px 1px black;
  }

  @media screen and (max-width: 768px) {
    h4 {
      font-size: 12px;
      gap: 11px;

      &::before {
        width: 28px;
      }
    }

    h3 {
      font-size: 22px;
    }
  }
`;

export const ImageContainerWide = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 400px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 230px;
  }
`;

export const ImageContainerLong = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 700px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

export const ProjectFilter = styled.div`
  margin-top: 125px;
  margin-bottom: 125px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    li {
      color: var(--FFFFFC, #fffffc);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        color: var(--ECBA82, #ecba82);
      }

      &.active {
        color: var(--ECBA82, #ecba82);
        font-family: "verdana-bold";
        font-style: normal;
        text-transform: capitalize;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 70px;
    ul {
      flex-wrap: wrap;
      li {
        font-size: 16px;
      }
    }
  }
`;
