import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout'
import { ContactHeader, ContactMap, GetInTouch, GroupList, LeftSide, ListItems, ListText, RightSide } from './ContactUs.styles'
import { HeaderTextContainer } from './About.styles'
import { ContactForm, InputGroup } from '../components/common/GetInTouch.styles'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import Swal from 'sweetalert2'
import Button from '../components/reusable/Button'
import axe from '../config/axiosClient/axiosConfig'
import phone from '../assets/images/phone-call.svg'
import mail from '../assets/images/mail.svg'
import location from '../assets/images/map-pin.svg'
import PageLoader from '../components/reusable/PageLoader'
import { Helmet } from 'react-helmet'


const schema = yup.object({
    full_name: yup.string().matches(/^[a-zA-Z ]+$/, 'No numbers or special characters!').required('Please enter your full name!'),
    email: yup.string().required('Please enter your email address!'),
    message: yup.string().required('Please write your message!')
})

function ContactUs() {
    const [loading, setLoading] = useState(true)
    const { register, formState: { errors }, handleSubmit, watch, reset } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: '',
            email: '',
            message: ''
        }
    })

    const formData = watch()


    const submitData = async () => {
        setLoading(true)
        const res = await axe.post('/contact', formData)

        if (res.status !== 200) {
            setLoading(false)
            Swal.fire({
                text: 'Something went wrong please retry!',
                color: 'red',
                position: 'bottom-end',
                icon: "error",
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 4000,
            })
        }

        if (res.status === 200) {
            reset({
                full_name: '',
                email: '',
                message: ''
            })
            setLoading(false)
            Swal.fire({
                icon: "success",
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
                showConfirmButton: false,
                text: 'Thank you! Your message was successfully sent!',
                timer: 4000,
                color: 'green',
            })
        }
    }
    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])

    if (loading) {
        return <PageLoader />;
    }


    return (
        <>
            <Helmet>
                <title data-n-head="true">Na Kontaktoni | ARKITEKTI | Design Your Dream</title>
                <meta name="description" content="Na kontakti ne numrin e telefonit +355692080888 , ne adresen tone te emailit info@arkitekti.al . Gjithashtu mund te na vizitoni prane zyrave tone ne Rrugen e Durrësit, pranë hotel “Colosseo”, Hyrja 35, Apart. Nr. 4, Tiranë" />
                <meta property="og:url" content="https://arkitekti.al/contact-us" />
                <meta name="keywords" content="arkitekti, arkitekt, projektim, planifikim, dizejnim, supervizim, realizim, interior, exterior, studio, studjo, arkitekture, zyre, tirane, Shqiperi, dizajn, ndertesa, arredim, zyra, ndertesa, shtepi " />
                <meta property="og:locale" content="sq_AL" />
                <meta property="og:type" content="article" />
                <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta property="og:image" content="https://arkitekti.al/images/icon.png" />

            </Helmet>
            <Layout>
                <ContactHeader>
                    <HeaderTextContainer>
                        <h2 className='font-verdanaBold'>Contact Us</h2>
                        <p className='font-roboto font-bold'>Schedule a meeting with us</p>
                    </HeaderTextContainer>

                </ContactHeader>

                <GetInTouch>
                    <LeftSide>
                        <GroupList>
                            <li>
                                <ListItems>
                                    <img src={phone} alt='phone-icon' />
                                    <ListText >
                                        <h2 className='font-verdanaBold'>Call Anytime</h2>
                                        <p className='font-roboto font-bold'>+355 69 208 0888</p>
                                    </ListText>
                                </ListItems>

                            </li>
                            <li>
                                <ListItems>
                                    <img src={mail} alt='mail-icon' />
                                    <ListText >
                                        <h2 className='font-verdanaBold'>Write Email</h2>
                                        <p className='font-roboto font-bold'>info@arkitekti.al</p>
                                    </ListText>
                                </ListItems>
                            </li>
                            <li>
                                <ListItems>
                                    <img src={location} alt='location-icon' />
                                    <ListText >
                                        <h2 className='font-verdanaBold'>Visit Office</h2>
                                        <p className='font-roboto font-bold me-3 md:me-0'>Rruga e Durrësit, Pranë Hotel “Colosseo”, Hyrja 35, Apart. Nr. 4, Tiranë</p>
                                    </ListText>
                                </ListItems>
                            </li>
                        </GroupList>

                    </LeftSide>
                    <RightSide>
                        <ContactForm onSubmit={handleSubmit(submitData)}>
                            <InputGroup>
                                <input type="text" {...register('full_name')} placeholder="Full Name" className='bg-transparent' />
                                {errors.full_name?.message && <p className='text-red-900 font-roboto'>{errors.full_name?.message}</p>}
                            </InputGroup>
                            <InputGroup>
                                <input type="email" {...register('email')} id="email" placeholder="Email" className='bg-transparent' />
                                {errors.email?.message && <p className='text-red-900 font-roboto'>{errors.email?.message}</p>}
                            </InputGroup>
                            <InputGroup>
                                <textarea {...register('message')} cols="30" rows="3" placeholder='Message'></textarea>
                                {errors.message?.message && <p className='text-red-900 font-roboto'>{errors.message?.message}</p>}
                            </InputGroup>
                            <Button type='submit' label={`Send`} loading={loading} />
                        </ContactForm>
                    </RightSide>
                </GetInTouch>

                <ContactMap>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2321.550220358705!2d19.80883846130459!3d41.33172252718759!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350318030895f97%3A0xe0484bf63b1bbc5!2sARKITEKTI!5e0!3m2!1sen!2s!4v1703716790403!5m2!1sen!2s" width="1444" height="550" style={{ 'border': '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </ContactMap>

            </Layout>
        </>
    )
}

export default ContactUs