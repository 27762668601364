import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  projects: [],
  error: null,
  architecture: [],
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    projectsPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    projectsRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    projectsSuccess: (state, { payload }) => {
      state.loading = false;
      state.projects = payload.data.projects;
      state.architecture = payload.data.architecture;
    },
  },
});
