import React, { useState } from 'react'
import { LayoutMain } from './Layout.styles'
import Navigation from '../components/common/Navigation'
import MenuModal from '../components/common/MenuModal'
import { AnimatePresence, motion } from 'framer-motion'
import Footer from '../components/common/Footer'

function Layout({ children }) {
  const [openMenu, setOpenMenu] = useState(false)

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
  }

  const variants = {
    enter: {
      opacity: 0, transition: {
        ease: "linear",
        duration: 1,
      }
    },
    visible: {
      opacity: 1, transition: {
        ease: "linear",
        duration: 1,
      }
    },
    exit: {
      opacity: 0, transition: {
        ease: "linear",
        duration: 1,
      }
    }
  }


  return (
    <div className='bg-bgColor relative'>
      {openMenu && <MenuModal toggleMenu={toggleMenu} />}
      <Navigation toggleMenu={toggleMenu} openMenu={openMenu} />
      {!openMenu &&
        <LayoutMain as={motion.main} variants={variants} initial='enter' animate='visible' exit='exit'>
          {children}
          <Footer />
        </LayoutMain>
      }

    </div>
  )
}

export default Layout