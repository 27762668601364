import axe from "../../config/axiosClient/axiosConfig";
import caller from "./architectureCaller";

export const getArchitectureTypes = async (limit = 0) => {
  try {
    caller.architecturePending();
    let res = null;
    if (limit === 0) {
      res = await axe.get("/architecture");
    }

    if (limit !== 0) {
      res = await axe.get(`/architecture?limit=${limit}`);
    }

    caller.architectureSuccess(res.data);
  } catch (error) {
    caller.architectureRejected("Something went wrong!");
  }
};

export const getArchitectureType = async (id) => {
  try {
    caller.architectureSinglePending();
    const res = await axe.get(`/architecture/${id}`);
    caller.architectureSingleSuccess(res.data);
  } catch (error) {
    caller.architectureSingleRejected("Something went wrong!");
  }
};
