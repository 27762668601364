import axios from "axios";
import { BACKEND_API_URL } from "../urls";

const axe = axios.create();

axe.defaults.validateStatus = (status) => status < 500;

axios.defaults.headers["Accept"] = "application/json";

axe.defaults.baseURL = BACKEND_API_URL;

export default axe;
