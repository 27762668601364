import { motion } from "framer-motion";
import styled from "styled-components";

export const ButtonLinkStyles = styled(motion.div)`
  border: 0.5px solid var(--FFFFFC, #fffffc);
  padding: 11px 42px;
  display: inline-block;
  cursor: pointer;
  transition: all 300ms linear;

  a {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-transform: capitalize;
  }

  &:hover {
    border: 0.5px solid var(--FFFFFC, #fffffc);
    background: var(--ECBA82, #ecba82);
  }

  @media screen and (max-width: 768px) {
    padding: 8px 31px;
    a {
      font-size: 12px;
    }
  }
`;
