// export const BACKEND_API_URL = "http://localhost:8000/api";

// export const BACKEND_API_FILE_URL = "http://localhost:8000/storage";

// export const BACKEND_API_FILE_ARCHITECTURE_URL = "http://localhost:8000";

export const BACKEND_API_URL = "https://admin.arkitekti.al/api";

export const BACKEND_API_FILE_URL = "https://admin.arkitekti.al/storage";

export const BACKEND_API_FILE_ARCHITECTURE_URL = "https://admin.arkitekti.al";
