import React, { useEffect, useState } from 'react'
import Layout from '../layouts/Layout'
import { ArchitecturalContainer, ArchitecturalHeader, GridContainer, GridItem, ImageContainer, ButtonContainer, ModalContainer, ModalContent, IconContainer, SliderItem, SliderContainer, NextArrowStyles, PrevArrowStyles, ModalText } from './Architectural.styles'
import { HeaderTextContainer } from './About.styles'
import img1 from '../assets/images/image.png'
import img2 from '../assets/images/image_1.png'
import Button from '../components/reusable/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-slick'
import PageLoader from '../components/reusable/PageLoader'
import { useSelector } from 'react-redux'
import { getArchitectureTypes } from '../features/architecture/architectureActions'
import { BACKEND_API_FILE_ARCHITECTURE_URL } from '../config/urls'
import ModalArchitecture from '../components/common/ModalArchitecture'



function Architectural() {
    const { loading, architecture, total } = useSelector(store => store.architecture)

    const [showModal, setshowModal] = useState({
        visibility: false,
        id: ''
    })

    const handleModal = (id) => {
        setshowModal({
            visibility: !showModal.visibility,
            id: id
        })
    }

    const viewMoreTypes = () => {
        getArchitectureTypes(architecture.length)
    }

    useEffect(() => {
        getArchitectureTypes()
    }, [])

    if (loading) {
        return <PageLoader />;
    }

    return (
        <Layout>
            {showModal.visibility && <ModalArchitecture handleModal={handleModal} showModal={showModal} />}
            <ArchitecturalHeader>
                <HeaderTextContainer>
                    <h2 className='font-verdanaBold'>Architecture</h2>
                    <p className='font-roboto font-bold'>The World of architectural form and style</p>
                </HeaderTextContainer>
            </ArchitecturalHeader>
            <ArchitecturalContainer>
                <GridContainer>
                    {architecture.map((type, index) => (
                        <GridItem key={index}>
                            <ImageContainer>
                                <img src={BACKEND_API_FILE_ARCHITECTURE_URL + type.gallery[0].image} alt={type.name} onClick={() => handleModal(type.id)} />
                            </ImageContainer>
                            <h2 className='font-roboto'>{type.name}</h2>
                            <p className='font-verdana'>
                                {type.en_desc.slice(0, 150)}...
                            </p>
                        </GridItem>
                    ))}
                </GridContainer>
                {total > architecture.length && <ButtonContainer className='table mx-auto mb-[50px] md:mb-[70px]'>
                    <Button handleClick={() => viewMoreTypes()} label={'View More'} />
                </ButtonContainer>}
            </ArchitecturalContainer>
        </Layout>
    )
}

export default Architectural