import React, { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { AfterContainer, AfterLeftSide, AfterRightSide, AfterSection, BeforeContainer, BeforeSection, BeforeTitle, ImageContainerLong, ImageContainerWide, LeftSide, NextArrowStyles, OtherProjectsSection, PanoGrid, PanoGridItem, PanoLoader, PanoRenderer, PanoViewer, PanoramaSection, PrevArrowStyles, ProjectDescription, ProjectInfoSection, ProjectLongImg, ProjectTitle, ProjectWideImg, ProjectsList, RightSide, SectionImage, SectionText, SingleProjectHeader } from './SingleProject.styles'
import { HeaderTextContainer } from './About.styles'
import { Link, useParams } from 'react-router-dom';
import { BACKEND_API_FILE_URL } from '../config/urls'
import PageLoader from '../components/reusable/PageLoader';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux'
import { getProject, getProjectGalleryImage } from '../features/single/singleActions';
import { Spinner } from '@material-tailwind/react';
import PanoramicViewer from '../components/PanoramicViewer';
import { Helmet } from 'react-helmet';


const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <NextArrowStyles onClick={onClick}>
            <FontAwesomeIcon icon={faChevronLeft} color='#fffffc' size='xl' />
        </NextArrowStyles>
    );
}

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <PrevArrowStyles onClick={onClick}>
            <FontAwesomeIcon icon={faChevronRight} color='#fffffc' size='xl' />
        </PrevArrowStyles>
    );
}

function SingleProject() {
    const { slug } = useParams()
    const { loading, project, currentPano, relatedProjects } = useSelector(store => store.single)

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        dots: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const changePanorama = (id) => {
        getProjectGalleryImage(id)
    }

    useEffect(() => {
        getProject(slug)
    }, [slug])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [slug])

    if (loading) {
        return <PageLoader />;
    }

    return (
        <>
            <Helmet>
                <title>{`${project.seo_title}`} | ARKITEKTI | Design Your Dream</title>
                <meta name="description" content={`${project.seo_description}`} />
                <meta property="og:url" content={`https://arkitekti.al/project/${project.slug}`} />
                <meta name="keywords" content={`${project.seo_keywords}`} />
                <meta property="og:image" content="https://arkitekti.al/images/icon.png" />
            </Helmet>
            <Layout>
                <SingleProjectHeader>
                    <HeaderTextContainer>
                        <h2 className='font-verdanaBold'>{project.category && project.category.name}</h2>
                        <p className='font-roboto font-bold'>{project.title}</p>
                    </HeaderTextContainer>
                </SingleProjectHeader>

                <ProjectInfoSection>
                    <ProjectDescription>
                        <h2 className='font-verdanaBold'>{project.intro_title}</h2>
                        <p className='font-verdana'>{project.intro_descripton}</p>
                    </ProjectDescription>
                </ProjectInfoSection>

                <BeforeSection>
                    <BeforeContainer>
                        <LeftSide>
                            <SectionImage>
                                <img src={BACKEND_API_FILE_URL + '/' + project.before_image} alt="" />
                            </SectionImage>
                        </LeftSide>
                        <RightSide>
                            <SectionText>
                                <BeforeTitle>
                                    <h2 className='font-verdanaBold'>Before</h2>
                                    <p className='font-roboto font-bold'>{project.before_title}</p>
                                </BeforeTitle>
                                <h5 className='font-verdana'>{project.before_description}</h5>
                            </SectionText>
                        </RightSide>
                    </BeforeContainer>
                </BeforeSection>
                <AfterSection>
                    <AfterContainer>
                        <AfterLeftSide>
                            <SectionText>
                                <BeforeTitle>
                                    <h2 className='font-verdanaBold'>After</h2>
                                    <p className='font-roboto font-bold'>{project.after_title}</p>
                                </BeforeTitle>
                                <h5 className='font-verdana'>{project.after_description}</h5>
                            </SectionText>
                        </AfterLeftSide>
                        <AfterRightSide>
                            <SectionImage>
                                <img src={BACKEND_API_FILE_URL + '/' + project.after_image} alt="" />
                            </SectionImage>
                        </AfterRightSide>
                    </AfterContainer>
                </AfterSection>
                {/* {project.photos.length !== 0 && <PanoramaSection>
                    <HeaderTextContainer>
                        <h2 className='font-verdanaBold'>Virtual Tour</h2>
                        <p className='font-roboto font-bold'>want to see more?</p>
                    </HeaderTextContainer>
                    <PanoViewer>
                        {currentPano.panoLoading === true ? <PanoLoader>
                            <Spinner className='h-16 w-16 text-gray-900/50' color='white' />
                        </PanoLoader> :
                            <PanoRenderer className='w-100 h-[700px]' id='panoview-id'>
                                <PanoramicViewer base64Image={currentPano.image} type={currentPano.type} height={700} width={1200} containerId={'panoview-id'} />
                            </PanoRenderer>
                        }
                    </PanoViewer>
                    <PanoGrid>
                        {project.photos.filter(p => p.id !== currentPano.image_id).map((img, index) => (
                            <PanoGridItem key={index} onClick={() => changePanorama(img.id)}>
                                <img src={BACKEND_API_FILE_URL + '/' + img.image} alt="" />
                            </PanoGridItem>
                        ))}
                    </PanoGrid>
                </PanoramaSection>} */}
                <OtherProjectsSection>
                    <HeaderTextContainer>
                        <h2 className='font-verdanaBold'>Project</h2>
                        <p className='font-roboto font-bold'>Other Projects</p>
                    </HeaderTextContainer>
                    <ProjectsList>
                        <Slider {...settings}>
                            {relatedProjects.map((project, index) => (
                                project.long === 1 ? <div key={index}>
                                    <ProjectLongImg>
                                        <Link to={`/project/${project.slug}`}>
                                            <ProjectTitle>
                                                <h4 className='font-verdanaBold'>
                                                    {project.category.name}
                                                </h4>
                                                <h3 className='font-roboto font-bold'>
                                                    {project.title}
                                                </h3>
                                            </ProjectTitle>
                                            <ImageContainerLong>
                                                <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt={project.title} />
                                            </ImageContainerLong>
                                        </Link>
                                    </ProjectLongImg>
                                </div> : <div key={index}>
                                    <ProjectWideImg>
                                        <Link to={`/project/${project.slug}`}>
                                            <ProjectTitle>
                                                <h4 className='font-verdanaBold'>
                                                    {project.category.name}
                                                </h4>
                                                <h3 className='font-roboto font-bold'>
                                                    {project.title}
                                                </h3>
                                            </ProjectTitle>
                                            <ImageContainerWide>
                                                <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt={project.title} />
                                            </ImageContainerWide>
                                        </Link>
                                    </ProjectWideImg>
                                </div>
                            ))}

                            {/* <div>
                            <ProjectWideImg>
                                <Link>
                                    <ProjectTitle>
                                        <h4 className='font-verdanaBold'>
                                            Interior
                                        </h4>
                                        <h3 className='font-roboto font-bold'>
                                            Lorem ipsum dolor
                                        </h3>
                                    </ProjectTitle>
                                    <ImageContainer>
                                        <img src={img} alt="" />
                                    </ImageContainer>
                                </Link>
                            </ProjectWideImg>
                        </div> */}
                            {/* <div>
                            <ProjectWideImg>
                                <Link>
                                    <ProjectTitle>
                                        <h4 className='font-verdanaBold'>
                                            Interior
                                        </h4>
                                        <h3 className='font-roboto font-bold'>
                                            Lorem ipsum dolor
                                        </h3>
                                    </ProjectTitle>
                                    <ImageContainer>
                                        <img src={img1} alt="" />
                                    </ImageContainer>
                                </Link>
                            </ProjectWideImg>
                        </div>
                        <div>
                            <ProjectLongImg>
                                <Link>
                                    <ProjectTitle>
                                        <h4 className='font-verdanaBold'>
                                            Interior
                                        </h4>
                                        <h3 className='font-roboto font-bold'>
                                            Lorem ipsum dolor
                                        </h3>
                                    </ProjectTitle>
                                    <ImageContainer>
                                        <img src={img2} alt="" />
                                    </ImageContainer>
                                </Link>
                            </ProjectLongImg>
                        </div>
                        <div>
                            <ProjectWideImg>
                                <Link>
                                    <ProjectTitle>
                                        <h4 className='font-verdanaBold'>
                                            Interior
                                        </h4>
                                        <h3 className='font-roboto font-bold'>
                                            Lorem ipsum dolor
                                        </h3>
                                    </ProjectTitle>
                                    <ImageContainer>
                                        <img src={img1} alt="" />
                                    </ImageContainer>
                                </Link>
                            </ProjectWideImg>
                        </div> */}
                        </Slider>
                    </ProjectsList>
                </OtherProjectsSection>
            </Layout>
        </>
    )
}

export default SingleProject