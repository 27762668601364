import axe from "../../config/axiosClient/axiosConfig";
import caller from "./projectsCaller";

const divideArray = (array, chunkSize) => {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
};

export const getProjects = async (categorySlug = null, limit = 0) => {
  try {
    caller.projectsPending();
    let res = null;
    let data = null;
    if (categorySlug === null) {
      if (limit !== 0) {
        res = await axe.get(`/projects?limit=${limit}`);
      }

      if (limit === 0) {
        res = await axe.get(`/projects`);
      }

      if (res.status === 200) {
        let newArray = divideArray(res.data.data.projects, 4);

        let projects = [];

        newArray.forEach((item) => {
          item.forEach((el, index) => {
            if (index === 0 || index == 3) {
              let item = {
                category: el.category,
                category_id: el.category_id,
                cover_image: el.cover_image,
                id: el.id,
                slug: el.slug,
                title: el.title,
                long: 1,
              };
              projects.push(item);
            } else {
              let item = {
                category: el.category,
                category_id: el.category_id,
                cover_image: el.cover_image,
                id: el.id,
                slug: el.slug,
                title: el.title,
                long: 0,
              };
              projects.push(item);
            }
          });
        });

        data = {
          projects: projects,
          categories: res.data.data.categories,
          categorySelected: null,
          total: res.data.data.total,
        };
      }
    } else {
      if (limit === 0) {
        res = await axe.get(`/projects?category=${categorySlug}`);
      }

      if (limit !== 0) {
        res = await axe.get(
          `/projects?category=${categorySlug}&limit=${limit}`
        );
      }

      if (res.status === 200) {
        let newArray = divideArray(res.data.data.projects, 4);

        let projects = [];

        newArray.forEach((item) => {
          item.forEach((el, index) => {
            if (index === 0 || index == 3) {
              let item = {
                category: el.category,
                category_id: el.category_id,
                cover_image: el.cover_image,
                id: el.id,
                slug: el.slug,
                title: el.title,
                long: 1,
              };
              projects.push(item);
            } else {
              let item = {
                category: el.category,
                category_id: el.category_id,
                cover_image: el.cover_image,
                id: el.id,
                slug: el.slug,
                title: el.title,
                long: 0,
              };
              projects.push(item);
            }
          });
        });

        data = {
          projects: projects,
          categories: res.data.data.categories,
          categorySelected: categorySlug,
          total: res.data.data.total,
        };
      }
    }

    caller.projectsSuccess(data);
  } catch (error) {
    caller.projectsRejected("Something went wrong!");
  }
};
