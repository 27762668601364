import React from 'react'
import Layout from '../layouts/Layout'
import { AboutHeader, HeaderTextContainer, AboutInfoSection, LeftSide, RightSide, Wrapper, ImageWrapper, FounderWrapper, AboutBanner, TeamSection, TeamTitleContent } from './About.styles'
import arkitekti from '../assets/images/about-photo.jpg'
import GetInTouch from '../components/common/GetInTouch'
import aboutBanner from '../assets/images/about-banner.png'
import TeamSilder from '../components/common/TeamSilder'
import { Helmet } from 'react-helmet'

function About() {

  return (
    <>
      <Helmet>
        <title data-n-head="true">Rreth Nesh | ARKITEKTI | Design Your Dream</title>
        <meta name="description" content="ARKITEKT ofron paketa të plota shërbimesh që nga krijimi deri tek realizimi i projektit. Studio realizon studime dhe kërkime në fushën e arkitekturës, urbanisikës, dizajnit, interiorit dhe eksteriorit…" />
        <meta property="og:url" content="https://arkitekti.al/about-us" />
        <meta name="keywords" content="arkitekti, arkitekt, projektim, planifikim, dizejnim, supervizim, realizim, interior, exterior, studio, studjo, arkitekture, zyre, tirane, Shqiperi, dizajn, ndertesa, arredim, zyra, ndertesa, shtepi " />
        <meta property="og:locale" content="sq_AL" />
        <meta property="og:type" content="article" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta property="og:image" content="https://arkitekti.al/images/icon.png" />
      </Helmet>
      <Layout>
        <AboutHeader>
          <HeaderTextContainer>
            <h2 className='font-verdanaBold'>About Us</h2>
            <p className='font-roboto font-bold'>Arkitekti Studio</p>
          </HeaderTextContainer>
        </AboutHeader>
        <AboutInfoSection>
          <Wrapper>
            <LeftSide>
              <h3 className='font-verdanaBold'>
                Welcome to the world of creativity and vision at "ARKITEKTI" Studio
              </h3>
              <p className='font-verdana'>
                A distinguished Albanian design firm proudly founded in April 2022. Our core mission is to provide an extensive range of design services, specializing in buildings with diverse functions, enchanting parks, and captivating works of art.
                Embark on a journey with us as we navigate the realms of innovative design, shaping spaces that not only meet various needs but also serve as canvases for artistic expressions. At the heart of "ARKITEKTI" is a dedicated team of professionals encompassing a spectrum of expertise, including architecture, design, urban planning, and more. Together, we bring a wealth of knowledge and passion to every project, ensuring a harmonious blend of functionality and aesthetic brilliance. Join us in transforming ideas into tangible, awe-inspiring creations that leave a lasting impression on the landscape of design.
              </p>
            </LeftSide>
            <RightSide>
              <ImageWrapper>
                <img src={arkitekti} alt="" />
                <FounderWrapper>
                  <h6 className='font-verdanaBold'>Founder</h6>
                  <h4 className='font-roboto font-bold'>Eduard Sokoli</h4>
                </FounderWrapper>
              </ImageWrapper>
            </RightSide>
          </Wrapper>
        </AboutInfoSection>
        <AboutBanner>
          <img src={aboutBanner} alt="" />
        </AboutBanner>
        <TeamSection>
          <TeamTitleContent>
            <h4 className='font-verdanaBold'>
              Team Of Creators
            </h4>
            <h2 className='font-roboto'>
              “We love to design handcrafted interiors and think outside of the box on each of our project”
            </h2>
          </TeamTitleContent>
          <TeamSilder />
        </TeamSection>
        <GetInTouch />
      </Layout>
    </>
  )
}

export default About