import React from 'react'
import Layout from '../layouts/Layout'
import { MainSection, InfoContainer } from './NotFoundPage.styles'
import errorBg from "../assets/images/404.png";
import ButtonLink from '../components/reusable/ButtonLink';


function NotFoundPage() {
  return (
    <Layout>
      <MainSection bg={errorBg}>
        <InfoContainer>
          <h1 className='font-roboto font-bold'>404</h1>
          <h2 className='font-roboto font-bold'>That page can't be found</h2>
          <p className='font-verdana'>
            It looks like nothing found at this location. Try to navigate the menu or go to the home page.
          </p>
          <ButtonLink label={'Go To Home Page'} />
        </InfoContainer>
      </MainSection>
    </Layout>
  )
}

export default NotFoundPage