import styled from "styled-components";
import heroBg from "../assets/images/about-us.png";

export const AboutHeader = styled.section`
  background-image: url(${heroBg});
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 768px) {
    background-size: cover;
    background-position: center;
    min-height: 320px;
  }
`;

export const HeaderTextContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;

  h2 {
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #ecba82;
    }
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 50px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 16px;

      &::before {
        width: 30px;
      }
    }

    p {
      font-size: 30px;
    }
  }
`;

export const AboutInfoSection = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 150px;
  margin: 100px auto;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 70px auto;
    gap: 70px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    margin: 70px auto;
    gap: 70px;
  }
`;

export const LeftSide = styled.div`
  h3 {
    color: var(--FFFFFC, #fffffc);
    font-size: 20px;
    font-style: normal;
    line-height: 30px;
    text-transform: capitalize;
    width: 80%;
    margin-bottom: 20px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 18px;
      width: 100%;
    }
    p {
      font-size: 14px;
    }
  }
`;

export const RightSide = styled.div``;

export const ImageWrapper = styled.div`
  position: relative;
  img {
    max-width: 650px;
  }

  @media screen and (max-width: 768px) {
    img {
      max-width: 355px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    img {
      max-width: 520px;
    }
  }
`;

export const FounderWrapper = styled.div`
  width: 270px;
  height: 86px;
  background-color: #ecba82;
  position: absolute;
  bottom: 50px;
  left: -30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  h6 {
    color: var(--46494C, #46494c);
    font-size: 14px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    width: 65%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    margin-bottom: -5px;

    &::before {
      content: "";
      position: relative;
      width: 20px;
      height: 1px;
      background-color: #46494c;
    }
  }

  h4 {
    color: var(--FFFFFC, #fffffc);
    font-size: 26px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    width: 65%;
    margin: 0 auto;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 15px 0;
    border-color: transparent #b68043 transparent transparent;
    display: block;
    position: absolute;
    bottom: -15px;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    height: 50px;

    h6 {
      font-size: 10px;

      &::before {
        width: 10px;
      }
    }

    h4 {
      font-size: 14px;
      margin-bottom: 10px;

      &::before {
        border-width: 0 14px 7px 0;
      }
    }
  }
`;

export const AboutBanner = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 150px auto;

  @media screen and (max-width: 768px) {
    margin: 100px auto;
  }
`;

export const TeamSection = styled.div``;

export const TeamTitleContent = styled.div`
  max-width: 1170px;
  margin: 90px auto;
  h4 {
    color: #ecba82;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    &::before {
      content: "";
      position: relative;
      width: 310px;
      height: 1px;
      background-color: #fffffc;
    }

    &::after {
      content: "";
      position: relative;
      width: 310px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h2 {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  @media screen and (max-width: 768px) {
    margin: 40px auto;

    h4 {
      font-size: 16px;
      gap: 15px;
      margin-bottom: 10px;
      &::before {
        width: 90px;
      }

      &::after {
        width: 90px;
      }
    }

    h2 {
      font-size: 30px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    h2 {
      font-size: 30px;
      margin: 0 20px;
    }
  }
`;
