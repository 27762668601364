import styled from "styled-components";

export const NavigationStylesContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  background-color: transparent;

  @media screen and (max-width: 1024px) {
    background-color: #272727;
  }
`;

export const NavWrapper = styled.nav`
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 0px;
  }
`;

export const Logo = styled.img`
  max-width: 70px;
  max-height: 70px;
`;

export const HamburgerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  height: 70px;
  transition: all 300ms ease-in-out;
`;

export const LineOne = styled.span`
  display: block;
  background: #fff;
  height: 2px;
  border-radius: 10px;
  width: 60px;
  transition: all 300ms ease-in-out;
`;

export const LineTwo = styled.span`
  display: block;
  background: #fff;
  height: 2px;
  border-radius: 10px;
  width: 60px;
  transition: all 300ms ease-in-out;
`;
