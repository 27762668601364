import React, { useEffect, useState } from 'react'
import { ContactForm, InputGroup, LeftSide, RightSide, GetInTouchStyles } from './GetInTouch.styles'
import Button from '../reusable/Button'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import axe from '../../config/axiosClient/axiosConfig'
import Swal from 'sweetalert2'


const schema = yup.object({
    full_name: yup.string().matches(/^[a-zA-Z ]+$/, 'No numbers or special characters!').required('Please enter your full name!'),
    email: yup.string().required('Please enter your email address!'),
    message: yup.string().required('Please write your message!')
})

function GetInTouch() {
    const [loading, setLoading] = useState(false)
    const { register, formState: { errors, isSubmitSuccessful }, handleSubmit, watch, reset } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: '',
            email: '',
            message: ''
        }
    })

    const formData = watch()


    const submitData = async () => {
        setLoading(true)
        const res = await axe.post('/contact', formData)

        if (res.status !== 200) {
            setLoading(false)
            Swal.fire({
                text: 'Something went wrong please retry!',
                color: 'red',
                position: 'bottom-end',
                icon: "error",
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 4000,
            })
        }

        if (res.status === 200) {
            reset({
                full_name: '',
                email: '',
                message: ''
            })
            setLoading(false)
            Swal.fire({
                icon: "success",
                toast: true,
                position: "bottom-right",
                timerProgressBar: true,
                showConfirmButton: false,
                text: 'Thank you! Your message was successfully sent!',
                timer: 4000,
                color: 'green',
            })
        }
    }

    return (
        <GetInTouchStyles>
            <LeftSide>
                <h3 className='font-roboto'>
                    Have a Project in Mind?
                </h3>
                <p className='font-verdana'>
                    Contact us to turn your ideas into reality, because we are here to transform your visions into vibrant and functional architecture
                </p>
            </LeftSide>
            <RightSide>
                <ContactForm onSubmit={handleSubmit(submitData)} autoComplete='false'>
                    <InputGroup>
                        <input type="text" {...register('full_name')} placeholder="Full Name" className='bg-transparent' />
                        {errors.full_name?.message && <p className='text-red-900 font-roboto'>{errors.full_name?.message}</p>}
                    </InputGroup>
                    <InputGroup>
                        <input type="email" {...register('email')} id="email" placeholder="Email" className='bg-transparent' />
                        {errors.email?.message && <p className='text-red-900 font-roboto'>{errors.email?.message}</p>}
                    </InputGroup>
                    <InputGroup>
                        <textarea {...register('message')} cols="30" rows="3" placeholder='Message' ></textarea>
                        {errors.message?.message && <p className='text-red-900 font-roboto'>{errors.message?.message}</p>}
                    </InputGroup>
                    <Button type='submit' label={`Send`} loading={loading} />
                </ContactForm>
            </RightSide>
        </GetInTouchStyles>
    )
}

export default GetInTouch