import axe from "../../config/axiosClient/axiosConfig";
import caller from "./homeCaller";

export const getProjects = async () => {
  caller.projectsPending();
  const res = await axe.get("/get-projects");

  if (res.status !== 200) {
    caller.projectsRejected("Something went wrong!");
  }

  if (res.status === 200) {
    caller.projectsSuccess(res.data);
  }
};
