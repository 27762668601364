import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  project: null,
  relatedProjects: [],
  currentPano: {
    image: null,
    type: null,
    image_id: null,
    panoLoading: false,
  },
  error: null,
};

export const singleSlice = createSlice({
  name: "single",
  initialState,
  reducers: {
    singlePending: (state) => {
      state.loading = true;
      state.error = null;
    },
    singleSuccess: (state, { payload }) => {
      state.loading = false;
      state.project = payload.data.project;
      state.currentPano.image = payload.data.image;
      state.currentPano.image_id = payload.data.image_id;
      state.currentPano.type = payload.data.type;
      state.relatedProjects = payload.data.relatedProjects;
    },
    singleRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    singlePanoPending: (state) => {
      state.currentPano.panoLoading = true;
      state.currentPano.error = null;
    },
    singlePanoRejected: (state, { payload }) => {
      state.currentPano.panoLoading = true;
      state.currentPano.error = payload;
    },
    singlePanoSuccess: (state, { payload }) => {
      state.currentPano.panoLoading = false;
      state.currentPano.image = payload.image;
      state.currentPano.image_id = payload.image_id;
      state.currentPano.type = payload.type;
    },
  },
});
