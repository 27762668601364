import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import SingleTeam from "./pages/SingleTeam";
import Architectural from "./pages/Architectural";
import SingleProject from "./pages/SingleProject";
import NotFoundPage from "./pages/NotFoundPage";



const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: "/projects",
    element: <Projects />
  },
  {
    path: '/about-us',
    element: <About />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/team/:slug',
    element: <SingleTeam />
  },
  {
    path: '/architectural',
    element: <Architectural />
  },
  {
    path: '/project/:slug',
    element: <SingleProject />
  },
  {
    path: '*',
    element: <NotFoundPage />
  }
])

function App() {
  return <RouterProvider router={router} />;
}

export default App;
