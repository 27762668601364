import axe from "../../config/axiosClient/axiosConfig";
import caller from "./singleCaller";

const divideArray = (array, chunkSize) => {
  let result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
};

export const getProject = async (slug) => {
  try {
    let data = {
      project: null,
      image: null,
      type: null,
      image_id: null,
      relatedProjects: [],
    };
    const res = await axe.get(`/project/${slug}`);
    let newArray = divideArray(res.data.data.relatedProjects, 4);

    let projects = [];

    newArray.forEach((item) => {
      item.forEach((el, index) => {
        if (index === 0 || index == 3) {
          let item = {
            category: el.category,
            category_id: el.category_id,
            cover_image: el.cover_image,
            id: el.id,
            slug: el.slug,
            title: el.title,
            long: 1,
          };
          projects.push(item);
        } else {
          let item = {
            category: el.category,
            category_id: el.category_id,
            cover_image: el.cover_image,
            id: el.id,
            slug: el.slug,
            title: el.title,
            long: 0,
          };
          projects.push(item);
        }
      });
    });

    data.project = res.data.data.project;
    data.relatedProjects = projects;

    if (res.data.data.project.photos.length > 0) {
      const response = await axe.get(
        `/gallery/${res.data.data.project.photos[0].id}`
      );

      if (response.status === 200) {
        data.image = response.data.image;
        data.image_id = response.data.image_id;
        data.type = response.data.type;
      }
    }

    caller.singleSuccess({
      data,
    });
  } catch (error) {
    caller.singleRejected({
      error: "Something Went Wrong!",
    });
  }
};

export const getProjectGalleryImage = async (id) => {
  try {
    let data = {
      image: null,
      type: null,
      image_id: null,
    };
    caller.singlePanoPending({
      currentPano: {
        panoLoading: true,
      },
    });
    const res = await axe.get(`/gallery/${id}`);
    data.image = res.data.image;
    data.image_id = res.data.image_id;
    data.type = res.data.type;
    caller.singlePanoSuccess(data);
  } catch (error) {
    caller.singlePanoRejected({
      error: "Something Went Wrong!",
    });
  }
};
