import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  projects: [],
  categories: [],
  error: null,
  categorySelected: null,
  total: null,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    projectsPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    projectsRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    projectsSuccess: (state, { payload }) => {
      state.loading = false;
      state.projects = payload.projects;
      state.categories = payload.categories;
      state.categorySelected = payload.categorySelected;
      state.total = payload.total;
    },
  },
});
