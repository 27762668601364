import React, { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { HeaderTextContainer } from './About.styles'
import { CoverImage, Info, InfoContainer, LeftSide, RightSide, SingleTeamHeader } from './SingleTeam.styles'
import { BACKEND_API_FILE_URL } from '../config/urls'
import { useParams } from 'react-router'
import PageLoader from '../components/reusable/PageLoader'
import { getTeamMember } from '../features/team/teamActions'
import { useSelector } from 'react-redux'

function SingleTeam() {
    const { slug } = useParams()
    const { loading, team } = useSelector(store => store.team)

    useEffect(() => {
        getTeamMember(slug);
    }, [])


    if (loading) {
        return <PageLoader />;
    }

    return (
        <Layout>
            <SingleTeamHeader>
                <HeaderTextContainer>
                    <h2 className='font-verdanaBold'>{team.position}</h2>
                    <p className='font-roboto font-bold'>{team.first_name} {team.last_name}</p>
                </HeaderTextContainer>
            </SingleTeamHeader>


            <InfoContainer>
                <LeftSide>
                    <CoverImage>
                        <img src={BACKEND_API_FILE_URL + '/' + team.image} alt="" />

                    </CoverImage>
                </LeftSide>
                <RightSide>
                    <Info>
                        <h3 className='font-verdanaBold'>Bio </h3>
                        <p className='font-verdana'>{team.bio}</p>
                        <h4 className='font-verdanaBold'>Contact me:<span className='font-verdana'> {team.email}</span></h4>
                    </Info>
                </RightSide>

            </InfoContainer>
        </Layout>
    )
}

export default SingleTeam