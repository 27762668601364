import styled from "styled-components";

export const MainSection = styled.section`
  background: url(${(props) => props.bg}) center center/cover no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  h1 {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 120px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  h2 {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    width: 95%;
    h1 {
      font-size: 80px;
    }

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 20px;
    }
  }
`;
