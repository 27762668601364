import React from 'react'
import { FooterStyles, FooterContainer, ColTwo, ColThree, ColOne, FooterIcons } from './Footer.styles'
import logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import facebook from '../../assets/images/icons/facebook.svg'
import instagram from '../../assets/images/icons/instagram.svg'
import linkedin from '../../assets/images/icons/linkedin.svg'

function Footer() {

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <FooterStyles>
      <FooterContainer>
        <ColOne>
          <Link to={'/'} onClick={scrollTop}>
            <img src={logo} alt="Arkitekti Logo" />
          </Link>
        </ColOne>
        <ColTwo>
          <ul>
            <li className='font-verdana'>
              <Link to={'/'}>Home</Link>
            </li>
            <li className='font-verdana'>
              <Link to={'/about-us'}>About Us</Link>
            </li>
            <li className='font-verdana'>
              <Link to={'/projects'}>Projects</Link>
            </li>
            <li className='font-verdana'>
              <Link to={'/architectural'}>Architectural</Link>
            </li>
            <li className='font-verdana'>
              <Link to={'/contact-us'}>Contact</Link>
            </li>
          </ul>
        </ColTwo>
        <ColThree>
          <p className='text-end font-verdana'>
            Rruga e Durrësit, pranë hotel “Colosseo”, Hyrja 35, Apart. Nr. 4, Tiranë
          </p>
          <a href="mailto:info@arkitekti.al" className='font-verdana underline'>info@arkitekti.al</a>
          <a href="tel:+355692080888" className='font-verdana'>+355 69 208 0888</a>
          <FooterIcons>
            <a href="https://www.facebook.com/profile.php?id=100086408392316&mibextid=LQQJ4d" target='_blank'>
              <img src={facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/arkitektiofficial" target='_blank' >
              <img src={instagram} alt="" />
            </a>
            <a href="https://www.linkedin.com/company/arkitekti" target='_blank'>
              <img src={linkedin} alt="" />
            </a>
          </FooterIcons>
        </ColThree>
      </FooterContainer>
      <p className='font-verdana'>
        © Copyright {new Date().getFullYear()} Arkitekti.al | All Rights Reserved
      </p>
    </FooterStyles>
  )
}

export default Footer