import React from 'react'
import { ButtonStyles } from './Button.styles'
import { Spinner } from '@material-tailwind/react'

function Button({ handleClick, label, loading, type }) {
  return (
    <ButtonStyles type={type} onClick={handleClick}>{loading && <Spinner className="h-4 w-4" />} {label}</ButtonStyles>
  )
}

export default Button