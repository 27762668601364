import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  error: null,
  architecture: [],
  total: null,
  single: {
    loading: true,
    item: null,
    error: null,
  },
};

export const architectureSlice = createSlice({
  name: "architecture",
  initialState,
  reducers: {
    architecturePending: (state) => {
      state.loading = true;
      state.error = null;
    },
    architectureSuccess: (state, { payload }) => {
      state.loading = false;
      state.architecture = payload.data.types;
      state.total = payload.data.total;
    },
    architectureRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    architectureSinglePending: (state) => {
      state.single.loading = true;
      state.single.error = null;
    },
    architectureSingleSuccess: (state, { payload }) => {
      state.single.loading = false;
      state.single.item = payload.data;
    },
    architectureSingleRejected: (state, { payload }) => {
      state.single.loading = false;
      state.single.error = payload;
    },
  },
});
