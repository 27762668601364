import styled from "styled-components";

export const MenuModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  position: relative;

  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ModalLogoWrapper = styled.div`
  min-width: 800px;
  min-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: 15%;
  flex-direction: column;

  h2 {
    color: var(--FFFFFC, #fffffc);
    font-size: 35px;
    opacity: 0.7;
    font-style: bold;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -2px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    position: absolute;
    top: calc(50% - 350px);
    z-index: 99;
    min-width: 700px;
    min-height: 700px;
    margin-right: 0;
    margin-left: 0;

    img {
      opacity: 0.4;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
    }
  }
`;

export const MenuLeft = styled.div`
  @media screen and (max-width: 768px) {
    z-index: 9999;
    width: 100%;
  }
`;

export const MenuLeftList = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const MenuLeftListItem = styled.li`
  color: var(--FFFFFC, #fffffc);
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
  text-transform: capitalize;
  transition: all 300ms linear;

  &:hover {
    color: #ecba82;
  }

  @media screen and (max-width: 768px) {
    font-size: 30px;
    letter-spacing: -1.2px;
  }
`;

export const MenuRight = styled.div`
  @media screen and (max-width: 768px) {
    z-index: 9999;
    width: 100%;
  }
`;

export const MenuRightList = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;

  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`;

export const MenuRightListItem = styled.li`
  color: var(--FFFFFC, #fffffc);
  font-size: 50px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -2px;
  text-transform: capitalize;
  transition: all 300ms linear;
  cursor: pointer;

  &:hover {
    color: #ecba82;
  }
`;

export const MenuIconContainer = styled.div`
  width: 40px;
  height: 40px;
  transition: all 300ms linear;
  background-color: transparent;
  border-radius: 50%;

  &:hover {
    background-color: #ecba82;
  }
`;
