import React from 'react'
import { HomeSliderStyles, NextArrowStyles, PrevArrowStyles, SliderItem } from './ArchitectureSilder.styles'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_API_FILE_ARCHITECTURE_URL } from '../../config/urls';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <NextArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} color='#fffffc' size='xl' />
    </NextArrowStyles>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <PrevArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} color='#fffffc' size='xl' />
    </PrevArrowStyles>
  );
}

function ArchitectureSilder({ architecture, handleModal, showModal, setshowModal }) {

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    rows: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 1,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          rows: 2,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2,
          centerPadding: '20px'
        }
      }
    ]
  };

  return (
    <HomeSliderStyles>
      <Slider {...settings}>
        {architecture.map((type, index) => (
          <SliderItem key={index}>
            <img src={BACKEND_API_FILE_ARCHITECTURE_URL + type.gallery[0].image} alt={type.name} onClick={() => handleModal(type.id)} />
            <h3 className='font-roboto'>{type.name}</h3>
            <p className='font-verdana'>
              {type.en_desc.slice(0, 150)}...
            </p>
          </SliderItem>
        ))}
      </Slider>
    </HomeSliderStyles>
  )
}

export default ArchitectureSilder