import styled from "styled-components";
import heroHomepage from "../assets/images/heroHomepage.webp";

export const HeroContainer = styled.div`
  background-image: url(${heroHomepage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 850px;
  display: flex;
  position: relative;

  justify-content: flex-start;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(39, 39, 39);
    background: linear-gradient(
      180deg,
      rgba(39, 39, 39, 0) 0%,
      rgba(39, 39, 39, 1) 120%
    );
  }

  @media screen and (max-width: 768px) {
    height: 750px;
    background-size: cover;
    background-position: center;
  }
`;

export const HeroText = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  width: 100%;
  position: relative;
  z-index: 9999;
  h1 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
    width: 75%;

    & .home-hero-text {
      width: 100%;
    }

    h1 {
      font-size: 45px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 85%;

    & .home-hero-text {
      width: 65%;
    }

    h1 {
      font-size: 60px;
    }
  }

  @media (min-width: 1025px) and (max-width: 1440px) {
    width: 75%;

    & .home-hero-text {
      width: 58%;
    }

    h1 {
      font-size: 65px;
    }
  }
`;

export const AboutHomeSection = styled.section`
  max-width: 1440px;
  margin: 0px auto;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media (min-width: 769px) and (max-width: 1440px) {
    width: 90%;
  }
`;

export const AboutHomeLeft = styled.div`
  img {
    max-width: 800px;
    max-height: 900px;
  }

  @media screen and (max-width: 768px) {
    img {
      max-width: 370px;
      max-height: 900px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    img {
      max-width: 600px;
      max-height: 700px;
    }
  }
`;

export const AboutHomeRight = styled.div`
  max-width: 850px;
  max-height: 550px;
  padding: 90px 110px;
  background: var(--46494C, #46494c);
  box-shadow: 4px 4px 40px 0px rgba(0, 0, 0, 0.3);
  margin-left: -15%;

  @media screen and (max-width: 768px) {
    padding: 30px;
    max-width: 370px;
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding: 70px;
    max-width: 550px;
    margin-left: -25%;
  }
`;

export const ContentAboutHome = styled.div`
  h4 {
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    text-align: left;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h2 {
    color: var(--FFFFFC, #fffffc);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px;
    }

    h2 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    h4 {
      font-size: 18px;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }
`;

export const HomeProjects = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
`;

export const HomeProjectsTitleContent = styled.div`
  max-width: 850px;
  margin: 90px auto;
  h4 {
    color: #ecba82;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    &::before {
      content: "";
      position: relative;
      width: 265px;
      height: 1px;
      background-color: #fffffc;
    }

    &::after {
      content: "";
      position: relative;
      width: 265px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h2 {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  @media screen and (max-width: 768px) {
    margin: 40px auto;

    h4 {
      font-size: 16px;
      gap: 15px;
      margin-bottom: 10px;
      &::before {
        width: 90px;
      }

      &::after {
        width: 90px;
      }
    }

    h2 {
      font-size: 30px;
    }
  }
`;

export const HomeProjectsList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding-bottom: 100px;
  padding-top: 50px;
  gap: 50px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 60px;
    padding-top: 30px;
    row-gap: 50px;
    width: 90%;
    margin: 0 auto;
  }
`;

export const ProjectLongImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  transition: all 300ms linear;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const ProjectWideImg = styled.div`
  max-width: 640px;
  overflow: hidden;
  width: 100%;
  transition: all 300ms linear;

  @media screen and (max-width: 768px) {
    max-width: 370px;
  }
`;

export const ProjectTitle = styled.div`
  margin-bottom: -3%;
  z-index: 9999;
  position: relative;
  margin-left: 21px;
  h4 {
    position: relative;
    color: #ecba82;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 18px;
    margin-bottom: 0;

    &::before {
      content: "";
      position: relative;
      width: 50px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h3 {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    text-shadow: 1px 1px black;
  }

  @media screen and (max-width: 768px) {
    h4 {
      font-size: 12px;
      gap: 11px;

      &::before {
        width: 28px;
      }
    }

    h3 {
      font-size: 22px;
    }
  }
`;

export const ImageContainerWide = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 400px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 230px;
  }
`;

export const ImageContainerLong = styled.div`
  img {
    transition: all 300ms linear;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  transition: all 300ms linear;
  max-width: 640px;
  overflow: hidden;
  height: 700px;
  width: 100%;

  &:hover {
    img {
      transform: scale(1.02);
    }
  }

  @media screen and (max-width: 768px) {
    height: 400px;
  }
`;

export const VideoContainer = styled.div`
  img {
    width: 100vw;
    object-fit: cover;
  }
`;

export const HomeCategories = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
  }
`;

export const HomeCategoriesTitleContent = styled.div`
  max-width: 850px;
  margin: 120px auto;
  display: flex;
  flex-direction: column;
  h4 {
    color: #ecba82;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;

    &::before {
      content: "";
      position: relative;
      width: 265px;
      height: 1px;
      background-color: #fffffc;
    }

    &::after {
      content: "";
      position: relative;
      width: 265px;
      height: 1px;
      background-color: #fffffc;
    }
  }

  h2 {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 214.286% */
    margin-top: 20px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    margin: 70px auto;

    h4 {
      font-size: 16px;
      gap: 15px;
      margin-bottom: 10px;
      &::before {
        width: 90px;
      }

      &::after {
        width: 90px;
      }
    }

    h2 {
      font-size: 30px;
    }
  }
`;
