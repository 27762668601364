import React from 'react'
import { PageLoaderStyles } from './PageLoader.styles'
import { Spinner } from '@material-tailwind/react'

function PageLoader() {
    return (
        <PageLoaderStyles>
            <Spinner className="h-16 w-16 text-gray-900/50" color='white' />
        </PageLoaderStyles>
    )
}

export default PageLoader