import { configureStore } from "@reduxjs/toolkit";
import { homeSlice } from "./home/homeSlice";
import { singleSlice } from "./single/singleSlice.js";
import { aboutSlice } from "./about/aboutSlice.js";
import { teamSlice } from "./team/teamSlice.js";
import { projectsSlice } from "./projects/projectsSlice.js";
import { architectureSlice } from "./architecture/architectureSlice.js";

export const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    single: singleSlice.reducer,
    about: aboutSlice.reducer,
    team: teamSlice.reducer,
    projects: projectsSlice.reducer,
    architecture: architectureSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
