import React from 'react'
import { ButtonLinkStyles } from './ButtonLink.styles'
import { Link } from 'react-router-dom'

function ButtonLink({ url, label, className }) {
  return (
    <ButtonLinkStyles className={className}>
      <Link to={url} className='font-roboto'>
        {label}
      </Link>
    </ButtonLinkStyles>
  )
}

export default ButtonLink