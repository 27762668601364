import styled from "styled-components";

export const HomeSliderStyles = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 300px;

  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const SliderItem = styled.div`
  width: 100% !important;
  cursor: pointer;

  .slick-slide,
  .slick-slide:focus {
    outline: none;
  }

  img {
    width: 95% !important;
  }

  & div {
    outline: none;
    border: none;
    box-shadow: none;
  }
  h3 {
    color: #fff;
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 95% !important;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
    width: 95% !important;
  }
  @media screen and (max-width: 768px) {
    display: table !important;
    margin: 30px auto;
    /* margin-left: 5px; */
    img {
      width: 370px;
    }
  }
`;

export const NextArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -25%;
  right: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -3%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;

export const PrevArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -25%;
  left: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -3%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;
