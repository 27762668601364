import styled from "styled-components";

export const FooterStyles = styled.footer`
  background-color: #151515;

  p {
    max-width: 1440px;
    width: 90%;
    margin: 0 auto;
    color: #f0f0f0;
    font-size: 12px;
    font-style: normal;
    line-height: 28px;
  }

  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    p {
      margin: 0 auto;
      text-align: center;
      padding-bottom: 20px;
    }
  }
`;

export const FooterContainer = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0px auto;
  padding-top: 130px;
  padding-bottom: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 100px;
    max-width: 90%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export const ColOne = styled.div`
  a {
    img {
      width: 170px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    a {
      img {
        width: 100px;
      }
    }
  }
`;

export const ColTwo = styled.div`
  margin-left: auto;
  ul {
    li {
      color: #fff;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 20px;
      transition: all 300ms linear;

      &:hover {
        a {
          color: #ecba82;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0px auto;

    ul {
      li {
        font-size: 20px;
      }
    }
  }
`;

export const ColThree = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 20px;

  p {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    line-height: 27px;
    text-transform: capitalize;
    width: 40%;
    margin-right: 0;
  }

  a {
    color: #fff;
    text-align: right;
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    align-items: flex-start;

    p {
      text-align: left;
      margin-left: 0;
      font-size: 16px;
      width: 75%;
    }

    a {
      text-align: left;
      font-size: 16px;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    width: 50%;

    p {
      width: 60%;
    }
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;

  a {
    width: 30px;
  }

  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
