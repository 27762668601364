import { store } from "./store";

export const callerCreator = (actions) => {
  const caller = {};

  const actionKeys = Object.keys(actions);
  const actionValues = Object.values(actions);

  actionKeys.forEach((actionKey, index) => {
    caller[actionKey] = (payload) =>
      store.dispatch(actionValues[index](payload));
  });

  return caller;
};
