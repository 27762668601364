import styled from "styled-components";

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }
`;

export const InputGroup = styled.div`
  border: none;
  width: 620px;
  margin-bottom: 30px;

  input {
    width: 620px;
    padding: 10px 12px;
    color: #fffffc;
    border-bottom: 0.5px solid #fffffc;

    &:focus {
      outline: none;
      border-bottom: 0.5px solid #fffffc;
    }
  }

  textarea {
    width: 620px;
    padding: 10px 12px;
    color: #fffffc;
    background-color: transparent;
    border-bottom: 0.5px solid #fffffc;

    &:focus {
      outline: none;
      border-bottom: 0.5px solid #fffffc;
    }
  }

  p {
    margin-top: 5px;
    font-size: 12px;
    margin-left: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 370px;
    width: 100%;
    margin: 0 auto;

    input {
      /* max-width: 370px; */
      width: 100%;
      margin: 0 auto;
    }

    textarea {
      /* width: 370px; */
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const LeftSide = styled.div`
  max-width: 620px;

  h3 {
    color: var(--FFFFFC, #fffffc);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    h3 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const RightSide = styled.div`
  margin-top: 100px;

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
`;

export const GetInTouchStyles = styled.div`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 200px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-bottom: 150px;
    gap: 70px;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
