import styled from "styled-components";

export const TeamSliderStyles = styled.section`
  max-width: 1440px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 300px;

  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
    margin-top: 80px;
  }
`;

export const SliderItem = styled.div`
  width: 90% !important;

  & div {
    outline: none;
    border: none;
  }
  h3 {
    color: #ecba82;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 25px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    &::before {
      content: "";
      position: relative;
      width: 20px;
      height: 1px;
      background-color: #ecba82;
    }
  }

  img {
    width: 467px;
    height: 500px;
    object-fit: cover;
  }

  p {
    color: var(--FFFFFC, #fffffc);
    font-size: 26px;
    font-style: normal;
    line-height: 26px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    display: table !important;
    margin: 30px auto;
    width: 100% !important;
    /* margin-left: 5px; */
    img {
      width: 370px;
      height: 450px;
    }
  }
`;

export const NextArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -25%;
  right: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -8%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;

export const PrevArrowStyles = styled.div`
  width: 50px;
  height: 50px;
  border: 0.5px solid #fffffc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: -25%;
  left: calc(50% + 20px);
  transition: all 300ms linear;

  &:hover {
    background-color: #fffffc;

    svg {
      color: #272727;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: -8%;
    width: 40px;
    height: 40px;

    svg {
      font-size: 16px;
    }
  }
`;
