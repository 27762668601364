import React from 'react'
import logo from '../../assets/images/logo.svg'
import { Link } from 'react-router-dom'
import { NavWrapper, NavigationStylesContainer, Logo, HamburgerContainer, LineOne, LineTwo } from './Navigation.styled'
import { AnimatePresence, motion } from 'framer-motion'


function Navigation({ toggleMenu, openMenu }) {

  const variants = {
    enter: {
      opacity: 0, transition: {
        ease: "linear",
        duration: 1,
      }
    },
    visible: {
      opacity: 1, transition: {
        ease: "linear",
        duration: 1,
      }
    },
    exit: {
      opacity: 0, transition: {
        ease: "linear",
        duration: 1,
      }
    }
  }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <NavigationStylesContainer>
      <NavWrapper>
        <AnimatePresence>
          <Link to={'/'} onClick={scrollTop}>
            {!openMenu && <Logo src={logo} alt="Arkitekti Logo" as={motion.img} variants={variants} initial='enter' animate='visible' exit='exit' />}
          </Link>
        </AnimatePresence>
        <HamburgerContainer onClick={() => toggleMenu()}>
          <LineOne></LineOne>
          {!openMenu &&
            <AnimatePresence>
              <LineTwo as={motion.span} variants={variants} initial='enter' animate='visible' exit='exit'></LineTwo>
            </AnimatePresence>
          }
        </HamburgerContainer>
      </NavWrapper>
    </NavigationStylesContainer>
  )
}

export default Navigation