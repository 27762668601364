import React, { useEffect } from 'react'
import { IconContainer, ModalContainer, ModalContent, ModalText, NextArrowStyles, PrevArrowStyles, SliderContainer } from '../../pages/Architectural.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import Slider from 'react-slick'
import { SliderItem } from './ArchitectureSilder.styles'
import { getArchitectureType } from '../../features/architecture/architectureActions'
import { Spinner } from '@material-tailwind/react'
import { BACKEND_API_FILE_ARCHITECTURE_URL } from '../../config/urls'
import { useSelector } from 'react-redux'

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <NextArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} color='#fffffc' size='sm' />
    </NextArrowStyles>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <PrevArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} color='#fffffc' size='sm' />
    </PrevArrowStyles>
  );
}

function ModalArchitecture({ handleModal, showModal }) {
  const { loading, item } = useSelector(store => store.architecture.single)

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  useEffect(() => {
    getArchitectureType(showModal.id)
  }, [showModal.id])


  return (
    <ModalContainer>
      <ModalContent>
        <IconContainer onClick={() => handleModal('')}>
          <FontAwesomeIcon icon={faTimes} color='#fffffc' size='2xl' />
        </IconContainer>
        {loading ?
          <div className='flex items-center justify-center w-full h-full'>
            <Spinner className='h-12 w-12' color='white' />
          </div>
          :
          <>
            <SliderContainer>
              <Slider {...settings}>
                {item.gallery.map((image, index) => (
                  <SliderItem key={index}>
                    <img src={BACKEND_API_FILE_ARCHITECTURE_URL + image.image} alt={item.name} className='w-100' onClick={() => handleModal('')} />
                  </SliderItem>
                ))}
              </Slider>
            </SliderContainer>
            <ModalText>
              <h2 className='font-roboto'>{item.name}</h2>
              <p className='font-verdana'>
                ALB: {item.alb_desc}
              </p>
              <p className='font-verdana'>
                ENG: {item.en_desc}
              </p>
            </ModalText>
          </>
        }
      </ModalContent>
    </ModalContainer>
  )
}

export default ModalArchitecture