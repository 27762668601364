import React, { useEffect } from 'react'
import { NextArrowStyles, PrevArrowStyles, SliderItem, TeamSliderStyles } from './TeamSilder.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import { BACKEND_API_FILE_URL } from '../../config/urls';
import { useNavigate } from 'react-router';
import PageLoader from '../reusable/PageLoader';
import { useSelector } from 'react-redux'
import { getTeamMembers } from '../../features/about/aboutActions';



const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <NextArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} color='#fffffc' size='xl' />
    </NextArrowStyles>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <PrevArrowStyles onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} color='#fffffc' size='xl' />
    </PrevArrowStyles>
  );
}

function TeamSilder() {
  const navigate = useNavigate()
  const { loading, team } = useSelector(store => store.about)

  const openSinglePage = (slug) => {
    navigate(`/team/${slug}`)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1300,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  useEffect(() => {
    getTeamMembers()
  }, [])

  if (loading) {
    return <PageLoader />;
  }


  return (
    <TeamSliderStyles>
      <Slider {...settings}>
        {team.map((teamMember, index) => (
          <SliderItem key={index} onClick={() => openSinglePage(teamMember.slug)}>
            <img src={BACKEND_API_FILE_URL + '/' + teamMember.image} alt="" />
            <h3 className='font-verdanaBold'>{teamMember.position}</h3>
            <p className='font-roboto font-bold'>{teamMember.first_name} {teamMember.last_name}</p>
          </SliderItem>
        ))}
      </Slider>
    </TeamSliderStyles>
  )
}

export default TeamSilder