import React, { useEffect } from 'react'
import Layout from '../layouts/Layout'
import { ProjectsHeader, HeaderTextContainer, ProjectsListContainer, ProjectsList, ProjectLongImg, ProjectTitle, ProjectWideImg, ProjectFilter, ImageContainerLong, ImageContainerWide } from './Projects.styles'
import { Link } from 'react-router-dom'
import Button from '../components/reusable/Button'
import { BACKEND_API_FILE_URL } from '../config/urls'
import PageLoader from '../components/reusable/PageLoader'
import { getProjects } from '../features/projects/projectsActions'
import { useSelector } from 'react-redux'


function Projects() {
  const { loading, projects, categories, categorySelected, total } = useSelector(store => store.projects)

  useEffect(() => {
    getProjects();
  }, [])

  const filterByCategory = (categorySlug) => {
    getProjects(categorySlug)
  }

  const viewMoreProjects = () => {
    getProjects(categorySelected, projects.length)
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Layout>
      <ProjectsHeader>
        <HeaderTextContainer>
          <h2 className='font-verdanaBold'>Projects</h2>
          <p className='font-roboto font-bold'>CREATIVE PROJECTS</p>
        </HeaderTextContainer>
      </ProjectsHeader>
      <ProjectsListContainer>
        <ProjectFilter>
          <ul>
            <li className={`font-verdana cursor-pointer ${categorySelected === null && 'active'}`} onClick={() => getProjects()}>
              All
            </li>
            {categories.map((category, index) => (
              <li className={`font-verdana cursor-pointer ${categorySelected === category.slug && 'active'}`} key={index} onClick={() => filterByCategory(category.slug)}>
                {category.name}
              </li>
            ))}
          </ul>
        </ProjectFilter>
        {projects.length !== 0 && <ProjectsList>
          {projects.map((project, index) => (
            project.long === 1 ?
              <ProjectLongImg key={index}>
                <Link to={`/project/${project.slug}`}>
                  <ProjectTitle>
                    <h4 className='font-verdanaBold'>
                      {project.category.name}
                    </h4>
                    <h3 className='font-roboto font-bold'>
                      {project.title}
                    </h3>
                  </ProjectTitle>
                  <ImageContainerLong>
                    <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt="" />
                  </ImageContainerLong>
                </Link>
              </ProjectLongImg> :
              <ProjectWideImg key={index}>
                <Link to={`/project/${project.slug}`}>
                  <ProjectTitle>
                    <h4 className='font-verdanaBold'>
                      {project.category.name}
                    </h4>
                    <h3 className='font-roboto font-bold'>
                      {project.title}
                    </h3>
                  </ProjectTitle>
                  <ImageContainerWide>
                    <img src={BACKEND_API_FILE_URL + '/' + project.cover_image} alt="" />
                  </ImageContainerWide>
                </Link>
              </ProjectWideImg>
          ))}
        </ProjectsList>}
        {projects.length === 0 && <p className='px-4 py-2 font-roboto text-center text-white mb-5'>No Projects found!</p>}
        {total > projects.length && <div className='table mx-auto mb-[50px] md:mb-[70px]'>
          <Button label={'View More Projects'} handleClick={() => viewMoreProjects()} />
        </div>}
      </ProjectsListContainer>
    </Layout>
  )
}

export default Projects